import React, { useContext, useEffect, useState } from "react";
import "../Styles/CartItems.css";
import { Button } from "react-bootstrap";
import { Link,useNavigate, Redirect,} from "react-router-dom";
import { DataContext } from "../Context/DataContext";
import { MQTTContext } from "../Context/MQTTContext";
import GlassErrorModal from "./GlassErrorModal";
import axios from "axios";
import Loader from "../Components/Loader";

const CartItems = () => {
  const {
    cartItems,
    setCartItems,
    updateCart,
    calculateOverallTotalPrice,
    calculateTotalPrice,
    TotalGlassQuantity,
    setOpenGlassError,
    openGlassError,
    db,
    TotalPrice,
    discountedAmount,
    machine_Id
  } = useContext(DataContext);
  const navigate=useNavigate()

  const { handlePublish, orderIdRef } = useContext(MQTTContext);
  const [paymentTarget, setPaymentTarget] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function generateRandomAlphaNumeric(length) {
    const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  const handleCheckout = async () => {
    setIsLoading(true)
   
    if (orderIdRef.current === null) {
      // Generate a new order ID when starting a new order
      // orderIdRef.current = Math.floor(Math.random() * 600);
      orderIdRef.current = generateRandomAlphaNumeric(6);
      localStorage.setItem('orderId',orderIdRef.current);
    }
    if(cartItems.length !== 0) {
      localStorage.setItem("cartItems",JSON.stringify(cartItems));
    }
    // const orderIdInput = paymentTarget.getElementsByName("order_id")[0];
    const ordIdInp = paymentTarget.querySelector('input[name="order_id"]');
    // console.log(ordIdInp,"ORDER INPUT");
    ordIdInp.value = orderIdRef.current
    console.log(paymentTarget,"Payment target",orderIdRef.current);
    const status = await handleAddToReport()
    if (status === 201) {
      setIsLoading(false)
      paymentTarget.submit();
      paymentTarget.addEventListener("submit", (e) => e.preventDefault());
    } else {
      
      navigate("/somethingwentwrong")
      

    }
    // paymentTarget.submit();
    // paymentTarget.addEventListener("submit",(e)=>e.preventDefault());
    const data = new FormData(paymentTarget);
    
    console.log(data,"Data from form data");
    // handlePublish();
  };
  // console.log(paymentTarget,"PaymentTarget");
  // React.useEffect(()=>{
  //   if(db) {
  //     const transaction = db.transaction(["orders"], "readwrite");
  //     const objectStore = transaction.objectStore("orders");

  //     console.log(cartItems,'Cart ITEMS before db');
  //     // objectStore.add()
  //   }
  // },[db])
  React.useEffect(()=>{

  },[cartItems])

  console.log("machineId--->", machine_Id)


  const handleAddToReport = async () => {
    const cartData = JSON.parse(localStorage.getItem("cartItems"));
    console.log("cartData--->", cartData);
    
    if (cartData && cartData.length > 0) {
      const ordersArray = [];
      const flavours = {};
      
      cartData.forEach((item) => {
        for (let i = 0; i < item.quantity; i++) {
          const orderNo = `${orderIdRef.current}${Math.floor(Math.random() * 99)}`; // Generate unique order number
          const masterOrderNo = orderIdRef.current; // Assuming this is generated dynamically
          const flavour = item.title;
          const glassStatus = "Empty"; // Example glass status
          
          const orderObject = {
            orderNo,
            masterOrderNo,
            flavour,
            title: flavour,
            quantity: 1,
            glassStatus,
            imageSrc: item.imageSrc, // Add imageSrc from cartData
            rate: item.rate, // Add rate from cartData
            Recipie: item.Recipie || item.recipie, // Add Recipie from cartData
            available: item.available, // Add available from cartData
          };
    
          ordersArray.push(orderObject);
          
          // Update flavours object with quantity for each flavour
          flavours[item.title] = (flavours[item.title] || 0) + 1; // Update quantity based on item quantity
        }
      });
    
      const machineId = machine_Id || 1; // Example machine ID
      const location = "Pune"; // Example location
      const paymentStatus = "Initiated"; // Example payment status
      const orderStatus = "Initiated"; // Example order status
      const orderValue = TotalPrice(); // Calculating overall order value
      const discountedPercentage = discountedAmount;
      const discountedValue =  calculateOverallTotalPrice()  // price after discount

  
      const newOrderObject = {
        orders: ordersArray,
        machineId,
        location,
        paymentStatus,
        flavours,
        orderStatus,
        orderValue,
        discountedValue,
        discountedPercentage,
        masterOrderNo: orderIdRef.current,
      };
  
      console.log("New Order Object:", newOrderObject);
      console.log("ordersArray-->", ordersArray);
      localStorage.setItem("ordersArray", JSON.stringify(ordersArray));
      localStorage.setItem("newOrderObject", JSON.stringify(newOrderObject));
      const status = await postData(newOrderObject);
      return status;

      // Further processing or API call with the new order object
    } else {
      console.log("No items found in cart.");
    }
  };
  
  let REACT_APP_URL= process.env.REACT_APP_URL

  const postData = async (data) => {
    try {
      const response = await axios.post(`${REACT_APP_URL}/order`, data);
      console.log("apiresponse-->", response);
      return response.status;
    
    } catch (error) {
      console.error("Error occurred while posting data:", error);
    }
  };

  const handleCancel = () => {
    localStorage.clear();
    setCartItems([]);
  };
  

  console.log("calculateOverallTotalPrice", calculateOverallTotalPrice())
  localStorage.setItem('totalPrice',calculateOverallTotalPrice());
  return (
    <>
      <>
        {/* <div style={{ minHeight: "360px" }}> */}
          {cartItems.some((item) => item.quantity > 0) && (
            <>
              <div className="product-item">
                {cartItems
                  .filter((item) => item.quantity > 0)
                  .map((item, index) => (
                    <div className="product-details" key={index}>
                      <div>
                        <img
                          src={require("../Assets/Glass.png")}
                          alt="img"
                          className="product-img"
                        />
                      </div>
                      <div className="product-title">{item.title}</div>
                      <div className="quantity-control">
                        <button
                          className={
                            item.quantity <= 1
                              ? "quantity-button-1"
                              : "quantity-button-2"
                          }
                          onClick={() => updateCart(item, item.quantity - 1)}
                          disabled={item.quantity === 1 ? true : false}
                        >
                          -
                        </button>
                        <div className="quantity-display"> {item.quantity}</div>
                        <button
                          className="quantity-button-2"
                          onClick={() => {
                            if (TotalGlassQuantity < 6) {
                              updateCart(item, item.quantity + 1);
                            } else {
                              setOpenGlassError(true);
                            }
                          }}
                        >
                          +
                        </button>
                      </div>
                      <div className="product-price">
                        ₹ {calculateTotalPrice(item)}.00
                      </div>
                      <div className="remove-button">
                        {/* <button
                          className="remove-button-btn"
                          onClick={() => updateCart(item, 0)}
                        >
                          Remove
                        </button> */}
                        <img src={require("../Assets/delete.png")} alt="img" onClick={() => updateCart(item, 0)}/>
                      </div>
                    </div>
                  ))}
                <div className="total-price-div">
                  <div>
                    <div className="total-price">Total</div>
                    <div className="total-price">Coupon Discount</div>
                    <div className="total-price">To Pay</div>
                  </div>

                  <div className="total-price-total-div">
                    <div className="total-price-total">
                    ₹ {TotalPrice()}.00
                    </div>
                    <div className="total-price-total">
                    - ₹ {discountedAmount}.00
                    </div>
                    <div className="total-price-total">₹  {calculateOverallTotalPrice()}.00</div>
                  </div>
                </div>
                {/* <div className="coupon-code">
                  <img src={require("../Assets/check.png")} alt="coupon-code" />
                  'FREE COUPON' Applied
                </div> */}
              </div>
            </>
          )}
        {/* </div> */}

        <div className="buttons">
          {cartItems.some((item) => item.quantity > 0) ? (
            <>
              
                <Button className="cancel-button" onClick={handleCancel}>Cancel</Button>
             
              <Button className="checkout-button" onClick={handleCheckout}>
                Checkout
              </Button>
            </>
          ) : (
            <>
              {/* <Link to="/">
                <Button style={{ marginTop: "30px" }} className="cancel-button">
                  Cancel
                </Button>
              </Link> */}
            </>
          )}
        </div>
      </>
      <GlassErrorModal
        show={openGlassError}
        onHide={() => setOpenGlassError(false)}
      />
       {isLoading && (
        <div style={{ width: "200px", margin: "auto", marginTop: "500px" }}>
          <Loader />
        </div>
      )}
      <PaymentForm setPaymentTarget={setPaymentTarget} calculateOverallTotalPrice={calculateOverallTotalPrice} orderIdRef={orderIdRef}/>
    </>
  );
};

export default CartItems;

const cloudURL = process.env.REACT_APP_CLOUD_URL;
// const cloudURL = "http://localhost:7070/api/payments";
function PaymentForm (props) {
  const { setPaymentTarget, calculateOverallTotalPrice, orderIdRef } = props;
  useEffect(()=>{ 
    setPaymentTarget(document.getElementsByName("customerData")[0]);
  },[])

  const machine_id= localStorage.getItem("machine_id")
  return (
    <form style={{display:"none"}} method="POST" name="customerData" action={`${cloudURL}/ccavRequestHandler`}>
      <table width="40%" height="100" border='1' align="center">
        <caption>
          <font size="4" color="blue"><b>Integration Kit</b></font>
        </caption>
      </table>
      <table width="40%" height="100" border='1' align="center">
        <tr>
          <td>Parameter Name:</td>
          <td>Parameter Value:</td>
        </tr>
        <tr>
          <td colspan="2">Compulsory information</td>
        </tr>
        <tr>
          <td>Merchant Id</td>
          <td><input type="text" name="merchant_id" id="merchant_id" value="1712378" /> </td>
        </tr>
        <tr>
          <td>Order Id</td>
          <td><input type="text" name="order_id"  value={orderIdRef.current} /></td>
        </tr>
        <tr>
          <td>Currency</td>
          <td><input type="text" name="currency" value="INR" /></td>
        </tr>
        {/* <tr>
          <td>Payment Option</td>
          <td><input type="text" name="payment_option" value="OPTUPI" /></td>
        </tr> */}
        <tr>
          <td>Amount</td>
          <td><input type="text" name="amount" value={`${calculateOverallTotalPrice()}.00`} /></td>
        </tr>
        <tr>
          <td>Redirect URL</td>
          <td><input type="text" name="redirect_url"
            value={`${cloudURL}/ccavResponseHandler`} />
          </td>
        </tr>
        <tr>
          <td>Cancel URL</td>
          <td><input type="text" name="cancel_url"
            value={`${cloudURL}/ccavResponseHandler`} />
          </td>
        </tr>
        <tr>
          <td>Language</td>
          <td><input type="text" name="language" id="language" value="EN" /></td>
        </tr>
        <tr>
          <td colspan="2">Billing information(optional):</td>
        </tr>
        {/* <tr>
          <td>Billing Name</td>
          <td><input type="text" name="billing_name" value="Peter" /></td>
        </tr>
        <tr>
          <td>Billing Address:</td>
          <td><input type="text" name="billing_address"
            value="Santacruz" /></td>
        </tr>
        <tr>
          <td>Billing City:</td>
          <td><input type="text" name="billing_city" value="Mumbai" /></td>
        </tr>
        <tr>
          <td>Billing State:</td>
          <td><input type="text" name="billing_state" value="MH" /></td>
        </tr>
        <tr>
          <td>Billing Zip:</td>
          <td><input type="text" name="billing_zip" value="400054" /></td>
        </tr>
        <tr>
          <td>Billing Country:</td>
          <td><input type="text" name="billing_country" value="India" />
          </td>
        </tr>
        <tr>
          <td>Billing Tel:</td>
          <td><input type="text" name="billing_tel" value="9876543210" />
          </td>
        </tr>
        <tr>
          <td>Billing Email:</td>
          <td><input type="text" name="billing_email"
            value="testing@domain.com" /></td>
        </tr>
        <tr>
          <td colspan="2">Shipping information(optional):</td>
        </tr>
        <tr>
          <td>Shipping Name</td>
          <td><input type="text" name="delivery_name" value="Sam" />
          </td>
        </tr>
        <tr>
          <td>Shipping Address:</td>
          <td><input type="text" name="delivery_address"
            value="Vile Parle" /></td>
        </tr>
        <tr>
          <td>Shipping City:</td>
          <td><input type="text" name="delivery_city" value="Mumbai" />
          </td>
        </tr>
        <tr>
          <td>Shipping State:</td>
          <td><input type="text" name="delivery_state" value="Maharashtra" />
          </td>
        </tr>
        <tr>
          <td>Shipping Zip:</td>
          <td><input type="text" name="delivery_zip" value="400038" /></td>
        </tr>
        <tr>
          <td>Shipping Country:</td>
          <td><input type="text" name="delivery_country" value="India" />
          </td>
        </tr>
        <tr>
          <td>Shipping Tel:</td>
          <td><input type="text" name="delivery_tel" value="0123456789" />
          </td>
        </tr> */}
        {/* <tr>
          <td>Merchant Param1</td>
          <td><input type="text" name="merchant_param1"
            value="additional Info." /></td>
        </tr> */}
        <tr>
          <td>Merchant Param2</td>
          <td><input type="text" name="merchant_param2"
            value="additional Info." /></td>
        </tr>
        <tr>
          <td>Merchant Param3</td>
          <td><input type="text" name="merchant_param3"
            value={machine_id} /></td>
        </tr>
        <tr>
          <td>Merchant Param4</td>
          <td><input type="text" name="merchant_param4"
            value="additional Info." /></td>
        </tr>
        <tr>
          <td>Merchant Param5</td>
          <td><input type="text" name="merchant_param5"
            value="additional Info." /></td>
        </tr>
        <tr>
          <td>Promo Code:</td>
          <td><input type="text" name="promo_code" value=""/></td>
        </tr>
        <tr>
          <td>Customer Id:</td>
          <td><input type="text" name="customer_identifier" value=""/></td>
        </tr>
        <tr>
          <td></td>
          <td><input type="submit" value="Checkout"/></td>
        </tr>
      </table>
    </form>
  )
};
