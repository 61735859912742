import React, { useContext } from "react";
import "../Styles/FeedBack.css";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Context/DataContext";

const FeedBack = () => {
  const { setCartItems } = useContext(DataContext);
  const navigate = useNavigate();
  const handleNavigate = () => {
    setCartItems([]);
    navigate("/");
  };
  return (
    <>
      <div className="feedback-main">
        <header className="feedback-header">
          <div className="feedback-logo">
            <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
          </div>
          <div className="feedback-header-text">
            <div className="feedback-header-text-1">Your Opinion Matters</div>
          </div>
        </header>
        <div className="feedback-main-img">
          <img
            src={require("../Assets/Better.png")}
            alt="Better"
            onClick={handleNavigate}
          />
          <img
            src={require("../Assets/Good.png")}
            alt="Good"
            onClick={handleNavigate}
          />

          <img
            src={require("../Assets/VeryGood.png")}
            alt="VeryGood"
            onClick={handleNavigate}
          />
        </div>
      </div>
    </>
  );
};

export default FeedBack;
