import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { QRCodeSVG , QRCodeCanvas} from "qrcode.react";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import lzString from "lz-string"; // Importing lz-string library for compression

const OrderQrCode = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [decryptedData, setDecryptedData] = useState(null);

  useEffect(() => {
    if (orderId) {
      // const cartItems = JSON.parse(localStorage.getItem("cartItems"));
      //added new way of sending data to qr code
      const cartItems = JSON.parse(localStorage.getItem("ordersArray"));

      console.log("CartItems", cartItems);
      const orders = cartItems?.map(
        ({ title, rate, quantity, Recipie, available, imageSrc }) => ({
          title,
          rate,
          quantity,
          Recipie,
          available,
          imageSrc,
        })
      );
      setOrderData(orders);
    }
  }, [orderId]);

  console.log("orderData-->", orderData);

  // Function to encrypt the data
  const encryptData = (data) => {
    const secretKey = "abcdefgh";
    console.log("data", data);
    const compressedData = lzString.compressToBase64(JSON.stringify(data)); // Compress the data
    console.log("compressedData", compressedData);
    return CryptoJS.AES.encrypt(compressedData, secretKey).toString();
  };

  // Function to decrypt the data
  const decryptDataToString = (encryptedData) => {
    const secretKey = "abcdefgh";
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(lzString.decompressFromBase64(decryptedData)); // Decompress the data
  };

  const encryptedData = encryptData({ orderId, orderData });

  const handleDecrypt = () => {
    setDecryptedData(decryptDataToString(encryptedData));
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `qrcode_${orderId}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log("encryptedData--->", encryptedData);
  console.log("decryptedData--->", decryptedData);

  return (
  //   <div>
  //   <div>
  //     {/* <QRCode
  //       value={orderId} // Sending the encrypted data directly
  //       style={{ height: "70%", maxWidth: "80%", width: "80%" }}
  //       renderAs="svg"
  //     /> */}

  //     <QRCodeSVG value={orderId} style={{ height: "50%", width: "60%" }} />
  //   </div>
  //   <div style={{ marginTop: "35px" }}>
  //     <button onClick={downloadQRCode} style={{ borderRadius: "4px" }}>
  //       Download QR Code
  //     </button>
  //   </div>
  // </div>

    <div>
      <div>
        {/* <QRCode
          value={orderId} // Sending the encrypted data directly
          style={{ height: "70%", maxWidth: "80%", width: "80%" }}
          renderAs="svg"
        />

        <QRCodeSVG value={orderId} style={{ height: "50%", width: "60%" }} /> */}
        <QRCodeCanvas value={orderId} style={{ height: "50%", width: "60%" }} />
        
      </div>
      <div style={{ marginTop: "35px" }}>
        <button onClick={downloadQRCode} style={{ borderRadius: "4px" }}>
          Download QR Code
        </button>
      </div>
    </div>
  );
};

export default OrderQrCode;
