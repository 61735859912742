import React from "react";
import CartItems from "./CartItems";

const products = [
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Plain Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Ginger Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Kokum Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Plain Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Ginger Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
  {
    imgSrc: require("../Assets/Glass.png"),
    title: "Kokum Cane Juice",
    price: "₹ 100.00",
    deleteImgSrc: require("../Assets/delete.png"),
  },
];

const Cart = () => {
  return (
    <div>
      <CartItems product={products} />
    </div>
  );
};

export default Cart;
