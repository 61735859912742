import "./App.css";
import { Route, Routes } from "react-router-dom";

import SelectFlavor from "./Screens/SelectFlavor";
import MakePayment from "./Screens/MakePayment";
import PaymentSuccessfull from "./Screens/PaymentSuccessfull";
import OrderComplete from "./Screens/OrderComplete";
import FeedBack from "./Screens/FeedBack";
import PaymentFailed from "./Screens/PaymentFailed";
import MachineFailure from "./Screens/MachineFailure";
import OrderProcessing from "./Screens/OrderProcessing";
import { DataProvider } from "./Context/DataContext";
import { MQTTProvider } from "./Context/MQTTContext";
import { ToastProvider } from "./Context/ToastContext";
import Layout from "./Components/Layout";
import Reporting from "./Screens/Reporting";
import PrivateRoute from "./hoc/PrivateRoute";
import PaymentRedirect from "./Screens/PaymentRedirect";
import OrderQrCode from "./Components/OrderQrCode";
import SomethingWentWrongPage from "./Screens/SomethingWentWrongPage";
import CancelTransaction from "./Screens/CancelTransation";
import OutOfStock from "./Screens/OutOfStock";


function App() {
  return (
    <div className="App">
          <ToastProvider>

      <DataProvider>
        <MQTTProvider>
            <Layout>
              <Routes>
                {/* <Route path="/" element={<TouchToOrder />} /> */}
                <Route path="/" element={<SelectFlavor />} />
                <Route path="/makepayment" element={<MakePayment />} />
                <Route
                  path="/paymentsuccess"
                  element={<PaymentSuccessfull />}
                />
                <Route path="/ordercomplete" element={<OrderComplete />} />
                <Route path="/feedback" element={<FeedBack />} />
                <Route path="/paymentfailed" element={<PaymentFailed />} />
                <Route
                  path="/machinefailure/:orderId"
                  element={<MachineFailure />}
                />
                <Route
                  path="/orderprocessing/:orderId"
                  element={<OrderProcessing />}
                />
                <Route 
                  path="/reporting"
                  element={<Reporting/>}
                />
                <Route 
                  path="/paymentredirect"
                  element={<PaymentRedirect/>}
                />
                <Route 
                  path="/somethingwentwrong"
                  element={<SomethingWentWrongPage />}
                />
                <Route 
                path="/outofstock"
                element={<OutOfStock/>}
                />
                 <Route path='/orderqrcode' element={<OrderQrCode/>}/>
                 <Route path='/canceltransaction' element={<CancelTransaction />}  />
              </Routes>
            </Layout>
        </MQTTProvider>
      </DataProvider>
      </ToastProvider>

    </div>
  );
}

export default App;
