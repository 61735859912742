import React from 'react';
import CanebotMachineImage from '../Assets/CanebotMachineImage.png';
import '../Styles/ScanMachineQRError.css';

function ScanMachineQRError() {
  return (
    <div className="scan-machine-container">
      <h2>Welcome to CaneBOT</h2>
      <img 
        src={CanebotMachineImage} 
        alt="CaneBOT Machine" 
      />
      <p>
        Please visit the nearest CaneBOT machine and scan the Menu QR Code to place your order
      </p>
    </div>
  );
}

export default ScanMachineQRError;
