import React, { useEffect } from "react";
import lottie from "lottie-web";
import animationData from "../lotties/PaymentFailed.json";
import "../Styles/PaymentFailed.css";
import Footer from "../Components/Footer";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  useEffect(() => {
    const container = document.querySelector("#payment-failed");

    if (container) {
      // Load animation when the component mounts
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });

      // Clean up the animation when the component unmounts
      return () => {
        anim.destroy();
      };
    }
  }, []);

  const machine_id = localStorage.getItem('machine_id');

  return (
    <>
      <div className="payment-failed-container">
        <header className="payment-failed-header">
          <div className="payment-failed-header-logoImg">
            <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
          </div>
          <div className="payment-failed-text-main">
            <div className="payment-failed-text">Payment Failed </div>
          </div>
        </header>
        <div
          id="payment-failed"
        />

        <div className="payment-failed-support">
          For Support Please Contact
          <div>+919881234589</div>
        </div>
        <div className="buttons">
        <Link to={`/?machine_id=${machine_id}`}>
            <Button className="checkout-button">Cancel</Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
