import React from "react";
import "../Styles/BarCode.css";

const BarCode = () => {
  return (
    <div className="barcode-container">
      <div className="barcode-wrapper">
        <img src={require("../Assets/BarCode.png")} alt="barcode" />
      </div>
    </div>
  );
};

export default BarCode;
