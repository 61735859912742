import React from "react";
import "../Styles/Footer.css";
import footerImage from "../Assets/Footer.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-image-container">
        <img src={footerImage} alt="footer" className="footer-image" />
        <div className="footer-links">
          <Link
            className="links"
            to="https://www.canebot.com/tnc"
            target="_blank"
          >
            Terms & Conditions
          </Link>
          <Link
            className="links"
            to="https://canebot.com/policies#privacypolicy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            className="links"
            to="https://canebot.com/policies#deliverypolicy"
            target="_blank"
          >
            Delivery Policy
          </Link>
          <Link
            className="links"
            to="https://canebot.com/policies#returnrefundpolicy"
            target="_blank"
          >
            Refund Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
