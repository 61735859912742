import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DataContext } from "../Context/DataContext";
import "./../Styles/Reporting.css";

// const Url = process.env.REACT_APP_URL;
const Url = "http://localhost:9000/api";

// const rows = [
//     { id: 1, order_id:"10000",no_of_glass:"2", flavours:"1", status:"Complete",creation_time:"1697448761710" },
//     { id: 2, order_id:"10001",no_of_glass:"1", flavours:"4", status:"Complete",creation_time:"1697448561790" },
//     { id: 3, order_id:"10002",no_of_glass:"5", flavours:"2", status:"Machine Failure",creation_time:"1697448761710" },
//     { id: 4, order_id:"10003",no_of_glass:"2", flavours:"2", status:"Complete",creation_time:"1697448761712" },
//     { id: 5, order_id:"10004",no_of_glass:"3", flavours:"5", status:"Machine Failure",creation_time:"1697448761730" },
//     { id: 6, order_id:"10005",no_of_glass:"4", flavours:"4", status:"Complete",creation_time:"1697448761560" },
//     { id: 7, order_id:"10006",no_of_glass:"6", flavours:"1", status:"Complete",creation_time:"1697448761711" },
//   ];
  
  const columns = [
    { field: 'order_id', headerName: 'Order ID', width: 150 },
    { field: 'no_of_glass', headerName: 'No. of Glasses', width: 150 },
    { field: 'flavours', headerName: 'Flavours', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'creation_time', headerName: 'Time', width: 150 },
  ];



const Reporting = () => {
    const [response, setResponse] = useState({});
    const [rows, setRows] = useState([]);
    const [flavours, setFlavours] = useState({})
    const [days, setDays] = useState(1);
    const navigate = useNavigate();
    const {products} = useContext(DataContext);
    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());

    useEffect(()=>{
        // console.log('url',`${Url}/orderByGlass?days=${days}`)
        axios.get(`${Url}/orderByGlass?days=${days}`)
        .then((res)=>{
            setResponse(res.data);
        })
        .catch((err)=>console.log(err,'err'))
        axios.get(`${Url}/order?days=${days}`)
        .then((res)=>{
            setFlavours(res.data);
        }).catch((err)=>console.log(err,'error'))
    },[days])
    useEffect(()=>{
        let arr = [];
        if(response){
            for(let key in response){
                let record = {
                    id: key,
                    order_id: key,
                    no_of_glass: response[key].quantity,
                    flavours: response[key].receipe ? products.find((product)=>product.Recipie == response[key].receipe )?.title:"",
                    // flavours: Object.keys(response[key].flavours).join("\n"),
                    status: response[key].status,
                    creation_time: response[key].createdAt?.split("T")?.[0]
                }
                arr.push(record);
            }
            setRows(arr);
        }
    },[response])
    useEffect(()=>{
        let flav = {};
        let totalQ = 0;
        for(let date in flavours){
            let flavourObj = flavours[date].flavours;
            for(let item in flavourObj){
                if(!flav[item]){
                    flav[item] = flavourObj[item];
                }else flav[item] += flavourObj[item];
            }
            totalQ = totalQ + flavours[date].totalQuantity;
        }
        for (let flavour in flav){
            const element = document.getElementById(flavour)
            if(element) {
                const ele = element.querySelector(".value");
                if(ele) ele.innerHTML = flav[flavour] ? flav[flavour] : 0;
            }
        }
        const totalQuan = document.getElementById("total_quantity");
        if(totalQuan) totalQuan.innerHTML = `<div>Total Glasses</div><div>${flavours && flavours.totalGlasses}</div>`;


        const totalOrders = document.getElementById("total_orders");
        if(totalOrders) totalOrders.innerHTML = `<div>Total Orders</div><div>${flavours && flavours.totalMasterOrders}</div>`;
    },[flavours])
    return (
        <div>
            <header className="selectflavor-header">
                <div className="selectflavor-header-logo">
                    <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
                </div>

                <div className="selectflavor-header-text-main">
                    <div className="selectflavor-header-title-1">Reporting</div>
                </div>
            </header>
            <div style={{display:"flex",justifyContent:"space-evenly",maxWidth:"50%",margin:"auto"}}>
                <select onChange={(ev)=>setDays(ev.target.value)}>
                    <option value={1}>Today</option>
                    <option value={2}>2 days</option>
                    <option value={3}>3 days</option>
                    <option value={4}>4 days</option>
                    <option value={5}>5 days</option>
                    <option value={6}>6 days</option>
                    <option value={7}>7 days</option>
                </select>
                <Button style={{backgroundColor:"#b5d559",borderColor:"#b5d559"}} onClick={()=>navigate("/")}>
                    Back
                </Button>
            </div>
            <div className="reporting-container">
                {/* <div style={{display:"flex", justifyContent:'space-between'}}>
                    <div></div>
                    <div><input onChange={(ev)=>setEnd(ev.target.value)} type="date"/><input onChange={(ev)=>setStart(ev.target.value)} type='date'/></div>
                </div> */}
                <div>
                    <div className="flavour-card-container" >
                        <div className="flavour-card-quantity" id="Plain Cane">
                            <div>Plain Cane</div>
                            <div className="value">-</div>
                        </div>
                        <div className="flavour-card-quantity" id="Lemon Cane">
                            <div>Lemon Cane</div>
                            <div className="value">-</div>
                        </div>
                        <div className="flavour-card-quantity" id="Ginger Cane">
                            <div>Ginger Cane</div>
                            <div className="value">-</div>
                        </div>
                        <div className="flavour-card-quantity" id="Lemon & Ginger">
                            <div>Lemon & Ginger</div>
                            <div className="value">-</div>
                        </div>
                        <div className="flavour-card-quantity" id="Lemon & Salt">
                            <div>Lemon & Salt</div>
                            <div className="value">-</div>
                        </div>
                        <div className="flavour-card-quantity" id="Lemon Ginger & Salt">
                            <div>Lemon Ginger & Salt</div>
                            <div className="value">-</div>
                        </div>
                    </div>
                    <div style={{display:"flex",width:"60%",margin:"auto"}} >
                        <div></div>
                        <div className="total-orders" id="total_orders">-</div>
                        <div className="total-quantity" id="total_quantity">-</div>
                        <div></div>
                    </div>
                </div>
                <DataGrid 
                    rows={rows} 
                    columns={columns} 
                    className="datagrid" 
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 8,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'creation_time', sort: 'asc' }],
                          },
                        }} />
            </div>
        </div>
    )
}

export default Reporting;
