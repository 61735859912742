import React, { createContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import PropTypes from "prop-types";
const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  return (
    <ToastContext.Provider value={{ toast }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // default options
          className: "",
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          // options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContext;

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
