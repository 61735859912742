import React from "react";
import Modal from "react-bootstrap/Modal";
import error from "../Assets/error.png";
import errorBg from "../Assets/errorIconbg.png";
import "../Styles/GlassErrorModal.css";
import { Button } from "react-bootstrap";

const styles = {
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    border: "none",
  },
  Modal: {
    textAlign: "center",
    alignItems: "center",
    padding: "5px",
    boxShadow: "0px 3px 6px #00000029",
    opacity: "1",
    backgroundColor: "#F4F4FA",
    fontFamily: "sans-serif",
    height: "286px",
    borderRadius: "25px",
  },
  Header: {},
  CrossIcon: {
    marginTop: "38px",
    position: "absolute",
  },
  Title: {
    marginTop: "20px",
    color: "#000000",
  },
  Body: {
    padding: "5px",
    borderRadius: "25px",
  },
};

function GlassErrorModal(props) {
  return (
    <Modal
      {...props}
      centered
      dialogClassName="glass-error-custom-modal"
      size="lg"
    >
      <div style={styles.Modal}>
        <Modal.Body style={styles.Body}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={errorBg}
              width="128"
              style={{ position: "absolute", marginTop: "38px" }}
              alt="cross"
            />

            <img src={error} width="102" style={styles.CrossIcon} alt="cross" />
          </div>

          <div
            style={{ fontSize: "26px", fontWeight: "900", marginTop: "90px" }}
          >
            Error!
          </div>
          <div
            style={{ fontSize: "20px", fontWeight: "bold", marginTop: "10px" }}
          >
            The Maximum Glass Limit Is 6
          </div>
          <Button className="error-modal-button" onClick={props.onHide}>
            OK
          </Button>
          <div
            style={{
              background: "#EF5A5A",
              borderBottomRightRadius: "25px",
              borderBottomLeftRadius: "25px",
              margin: "33px 30px 0px 33px",
              boxShadow: "0px 3px 6px #00000029",
              height: "25px",
            }}
          ></div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default GlassErrorModal;
