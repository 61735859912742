import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import mqtt from "precompiled-mqtt";
import { useNavigate } from "react-router-dom";
import { DataContext } from "./DataContext";
import axios from "axios";
import ToastContext from "../Context/ToastContext";
export const MQTTContext = createContext();

let lastR;
let completedFlag = false
let firstPub = false;

let order = {
  items: [],
};

const extractNumber = {
  "One":1,
  "Two":2,
  "Three":3,
  "Four":4,
  "Five":5,
  "Six":6,
  "one":1,
  "two":2,
  "three":3,
  "four":4,
  "five":5,
  "six":6
}

const juiceFiles = [
  "plain_cane_1.mp3",
  "lemon_cane_1.mp3",
  "ginger_cane_1.mp3",
  "lemon_ginger_cane_1.mp3",
  "lemon_salt_cane_1.mp3",
  "lemon_ginger_salt_cane_1.mp3"
];

const audio = document.createElement("audio");
export const playAudio = (fileName, audio) => {
  audio.setAttribute("src",require(`./../Assets/${fileName}`));
  audio.setAttribute("autoplay",true);
  document.body.appendChild(audio);
  audio.play();
  setTimeout(()=>{
    audio.remove();
  },9000);
}

let currentGlass;

function ResponseStr(res){
  this.resStr = res;
  this.includes = function (text){
    if(!this.resStr) return false;
    return this.resStr.toLowerCase().includes(text);
  }
  
}


export const MQTTProvider = ({ children }) => {
  const qosOption = [
    {
      label: "0",
      value: 0,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
  ];
  // const Url = process.env.REACT_APP_URL;
  const Url = "http://localhost:9000/order-api";

  const [client, setClient] = useState(null);
  const [isSubed, setIsSub] = useState(false);
  const [payload, setPayload] = useState({});
  const [connectStatus, setConnectStatus] = useState("Connect");
  const [isLastGlassDispensed, setIsLastGlassDispensed] = useState(false);
  const [orderRes, setOrderRes] = useState();
  const [CB_AEPLRes, setCB_AEPLRes] = useState();
  const orderPayloadRef = useRef([]);
  const orderIdRef = useRef(null);
  const cartItemsRef = useRef();
  const navigate = useNavigate();
  const { cartItems, products, speech, setCartItems } = useContext(DataContext);
  const { audioRecords, setAudioRecords, setCall } = useContext(DataContext);
  const { toast } = useContext(ToastContext);

  const mqttConnect = (host, mqttOption) => {
    setConnectStatus("Connecting");
    setClient(mqtt.connect(host, mqttOption));
  };

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnectStatus("Connected");
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        setConnectStatus("Reconnecting");
      });
      client.on("message", (topic, message) => {
        try {
          const parsedMessage = JSON.parse(message.toString());
          const payload = { topic, message: parsedMessage };
          setPayload(payload);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      });
    }
  }, [client]);

  const handleConnect = () => {
    const host = "broker.hivemq.com";
    const port = 8000;
    const url = `wss://${host}:${port}/mqtt`;

    // const host = "localhost";
    // const port = 1884;
    // const url = `ws://${host}:${port}`; 

 
    const options = {
      keepalive: 3600, // 1 hour in seconds
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 3600000, // 1 hour in milliseconds
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
      rejectUnauthorized: false,
    };
    const clientId = `mqttjs_ + ${Math.random().toString(16).substr(2, 8)}`;
    options.clientId = clientId;

    mqttConnect(url, options);
  };

  const handleDisconnect = () => {
    mqttDisconnect();
  };

  useEffect(() => {
    // handleConnect();
  }, []);

  const mqttDisconnect = () => {
    if (client) {
      client.end(() => {
        setConnectStatus("Connect");
      });
    }
  };

  const mqttPublish = (context) => {

    console.log("context: " + context)
    if (client) {
      const { topic, qos, payload } = context;
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  };

  const mqttSub = (subscription, onMessageCallback) => {
    if (client) {
      const { topic, qos } = subscription;
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        setIsSub(true);
        // Set up a callback function to handle incoming messages on the subscribed topic
        client.on("message", (topic, message) => {
          // Invoke the provided callback when a message is received on the subscribed topic
          onMessageCallback(topic, message.toString());
        });
      });
    }
  };

  const mqttUnSub = (subscription) => {
    if (client) {
      const { topic } = subscription;
      client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
          return;
        }
        setIsSub(false);
      });
    }
  };

  const publishToMQTT = async (payload, topic, qos) => {
    const payloadObj = JSON.stringify(payload);
    mqttPublish({
      topic: topic,
      qos: qos,
      payload: payloadObj,
    });
  };

    const payloadForReport = async (glass)=>{
    // let glass = arr.find((item)=>item.processing == true);
    // console.log("glass data ",glass);
    let glassData = {...glass,flavours:{},machineId:1,status:"sucess",payment:""};
    glassData.orderNo = glassData.order_id;
    glassData.masterOrderNo = orderIdRef.current;
    delete glassData.order_id;
    delete glassData.order_quantity;
    delete glassData.pending_quantity;
    delete glassData.processing;
    postData(glassData); 
  }

  const postData = async (glassData) => {
    try {
      const data = {
        orderNo: glassData.orderNo,
        machineId: "1", // by default will be 1
        flavours: {},
      };
      // console.log(cartItemsRef.current,'cartItems');
      cartItemsRef.current.forEach((item) => {
        if(glassData.flavours[item.title] == undefined){
          glassData.flavours[item.title] = 1;
        }else glassData.flavours[item.title] += 1;
      });
      console.log(glassData,'glassdata');
      let url = Url.replace(/orderprocessing\/\d+/,"");
      const res = await axios.post(`${url}/order`, glassData);
    } catch (error) {
      console.log(error); 
    }
  };

  const publishFinalMessage = async () => {
    const finalMessage = {
      order_id: orderIdRef.current,
      order_status: 3, // Update with the correct value
      order_complete: 3, // Update with the correct value
      glass_count: 0,
      pending_quantity: 0,
    };
    await publishToMQTT(finalMessage, "AEPL_CB/ORDER", 0);
  };
  // Function to publish the next message
  const publishNextMessage = async () => {
    if (orderPayloadRef.current.length > 0) {
      const message = orderPayloadRef.current.shift();
      currentGlass = message;
      const orderTopic = "CB_AEPL/ORDER";
      const defaultQos = 0;
      setCB_AEPLRes(message);
      await publishToMQTT(message, orderTopic, defaultQos);
      // payloadForReport(message);
      firstPub = false;
    } else if (!isLastGlassDispensed) {
      // All glasses have been dispensed, and this is the first time we enter this block
      // Set isLastGlassDispensed to true to prevent this block from running again
      setIsLastGlassDispensed(true);
      // publishFinalMessage();
    }
  };

  useEffect(() => {
    // MQTT message handling logic
    const handleResponse = (topic, message) => {
      const response = JSON.parse(message);
      setOrderRes(response);
      switch (true) {
        case response.order_status === 1 && response.order_complete === 2:
          let condition = !(
            lastR &&
            lastR.order_status === 1 &&
            lastR.order_complete === 2
          );

          if (condition) {
            let condition1 = orderPayloadRef.current.find(
              (item) => item.processing === true
            );
            if (condition1) {
              publishNextMessage(response);
              // toast.success("Multi Order Check", condition.toString());
            }
          }
          break;
        case response.order_status === 3 && response.order_complete === 2:
          let arr = [];
          const opr = orderPayloadRef.current;
          for (let i = 0; i < opr.length; i++) {
            if (i == 0) {
              arr.push({ ...opr[i], processing: true });
            } else {
              arr.push({ ...opr[i], processing: false });
            }
          }
          orderPayloadRef.current = arr;

          if (arr.length === 0 && !completedFlag) {
            completedFlag = true
            setTimeout(() => {
              navigate("/ordercomplete");
              // playAudio("thank_you_message.mp3");
              // Delay navigation to the feedback screen by 5 seconds
              setTimeout(() => {
                navigate("/");
              }, 8000); // 8000 milliseconds = 8 seconds
            }, 4000);
          }
          if(currentGlass){
            const fileToPlay = juiceFiles[parseInt(currentGlass.order_receipe)-1];
            const audio = document.createElement("audio");
            playAudio(fileToPlay, audio);
          }
          // Fallthrough to case 1
          // Intentional fallthrough
          break;
        case response.order_status === 3 && response.order_complete === 3:
          navigate("/ordercomplete");
          // Delay navigation to the feedback screen by 5 seconds
          setTimeout(() => {
            navigate("/");
          }, 8000); // 8000 milliseconds = 8 seconds
          break;

        case response.order_status === 9 || response.order_complete === 9:
          navigate(`/machinefailure/${orderIdRef.current}`);
        // Fallthrough to default case
        // Intentional fallthrough
        default:
          console.log();
          break;
      }
      lastR = { ...response };
    };

    const responseTopic = "AEPL_CB/ORDER";
    mqttSub(
      {
        topic: responseTopic,
        qos: 0,
      },
      handleResponse
    );

    const handleError = (errorTopic, errorMessage) => {
      // Check the error message if needed and take appropriate action
      // For example, you can parse the error message as JSON and handle it accordingly
      const response = JSON.parse(errorMessage);

      // Iterate through the properties of the response object
      for (const propertyName in response) {
        if (response.hasOwnProperty(propertyName)) {
          const propertyValue = response[propertyName];

          switch (propertyValue) {
            case 1:
              console.log();
              break;
            case 0:
              console.log();
              break;
            case 9:
              navigate(`/machinefailure/${orderIdRef.current}`);
              return; // Exit the function if any property has a value of 9
            default:
              console.log();
              break;
          }
        }
      }

      // If none of the properties have a value of 9, you can handle other cases or log an error message
      console.log();
    };

    const errorTopic = "AEPL_CB/ERROR";
    mqttSub(
      {
        topic: errorTopic,
        qos: 0,
      },
      handleError
    );

    const handleReset = (errorTopic, errorMessage) => {
      // Check the error message if needed and take appropriate action
      // For example, you can parse the error message as JSON and handle it accordingly
      const response = JSON.parse(errorMessage);

      switch (true) {
        case response.reset_flag === 1:
          console.log();
          break;
        case response.reset_flag === 2:
          console.log();
          break;
        case response.reset_flag === 9:
          console.log();
        // navigate(`/machinefailure/${orderIdRef.current}`);
        // Fallthrough to default case
        // Intentional fallthrough
        default:
          console.log();
          break;
      }
    };

    const resetTopic = "CB_AEPL/RESET";
    mqttSub(
      {
        topic: resetTopic,
        qos: 0,
      },
      handleReset
    );

    // Cleanup: Unsubscribe from MQTT topic when the component unmounts
    return () => {
      if (client) {
        client.unsubscribe(responseTopic, (error) => {
          if (error) {
            console.error("Unsubscribe error", error);
          }
        });
      }
    };
  }, [client]);

  // ...
  const handlePublish = () => {
    if (orderIdRef.current === null) {
      // Generate a new order ID when starting a new order
      // orderIdRef.current = Math.floor(Math.random() * 600);
      const orderId = localStorage.getItem("orderId");
      orderIdRef.current = orderId;
    }
    navigate(`/orderprocessing/${orderIdRef.current}`);
    // postData(orderIdRef.current);
    // alert("cart ITEM : ", cartItems.length);
    if(cartItems.length === 0){
      let cartItms = JSON.parse(localStorage.getItem('cartItems'));
      cartItemsRef.current = cartItms;
    }else {
      cartItemsRef.current = [...cartItems];
    }
    
    // Initialize the pending quantity
    let pendingQuantity = cartItemsRef.current.reduce(
      (totalQuantity, item) => totalQuantity + item.quantity,
      0
    );

    // Iterate through each item in the cart
    for (const item of cartItemsRef.current) {
      const { Recipie, quantity } = item;
      for (let glass = 1; glass <= quantity; glass++) {
        // Create the message for the current glass
        const message = {
          order_id: orderIdRef.current + `${Math.floor(Math.random() * 99)}`, // Use the current order ID
          order_receipe: Recipie,
          order_quantity: 1,
          pending_quantity: pendingQuantity,
          processing: false,
          id: glass
        };
        // Push the message to the orderPayload array using the ref
        orderPayloadRef.current.push({...message});

        orderPayloadRef.current[0] = {...orderPayloadRef.current[0],processing: true};

        // Decrement the pending quantity for the next glass
        pendingQuantity--;

        // Check if this is the last glass
        if (pendingQuantity === 0) {
          setIsLastGlassDispensed(true);
        }
      }
    }

    // Start the process by publishing the first order message
    if(currentGlass){
      if(!firstPub){
        publishNextMessage();
        firstPub = true;
      }
    }else publishNextMessage();
    
    

  };

  // Use Effect to interpret the order from speech query
  useEffect(() => {
    if( audioRecords.length > 0 ) {
      // const quantity = Object.keys(extractNumber);
      // const product = products[0];
      // for(let i = 0; i < quantity.length; i++){
      //   // if(speech.includes(quantity[i])){
      //   //   // setCartItems([{...product,quantity: extractNumber[quantity[i]]}]);
      //   //   // console.log("Cart ITEMS : ",[{...product,quantity: extractNumber[quantity[i]]}]);
      //   //   // handlePublish();
      //   //   quantityOfGlass.quantity.push(extractNumber[quantity[i]]);
      //   // }
      // }
      let topRecord = audioRecords[audioRecords.length - 1];
      // console.log("Response of top record",topRecord.res)
      const resString = new ResponseStr(topRecord.res);
      // console.log(resString.includes("cane")," <--------- || -------->");
      // function includes(text){
      //   return speech.toLowerCase().includes(text);
      // }
      if(resString.includes("plain") || resString.includes("plane")){
        // order.items.push({...products[0], quantity: 1});
        setCartItems([...cartItems,{...products[0], quantity: 1}])
      }
      else if(resString.includes("lemon") && resString.includes("ginger") && resString.includes("salt")){
        // order.items.push({...products[5],quantity:1});
        setCartItems([...cartItems,{...products[5],quantity:1}])
      }
      else if(resString.includes("lemon") && resString.includes("salt")){
        // order.items.push({...products[4],quantity:1});
        setCartItems([...cartItems,{...products[4],quantity:1}])
      }
      else if(resString.includes("lemon") && resString.includes("ginger")){
        // order.items.push({...products[3],quantity:1});
        setCartItems([...cartItems,{...products[3],quantity:1}])
      }
      else if(resString.includes("ginger")){
        // order.items.push({...products[2],quantity:1});
        setCartItems([...cartItems,{...products[2],quantity:1}])
      }
      else if(resString.includes("lemon")){
        // order.items.push({...products[1],quantity:1});
        setCartItems([...cartItems,{...products[1],quantity:1}])
      }
      else if(resString.includes("brother give a sugarcane juice") || (resString.includes("sugarcane"))) {
        setCall(1);
      }
      setTimeout(()=>{
        const checkoutButton = document.querySelector(".checkout-button");
        if(checkoutButton){
          checkoutButton.click();
        }else {
          console.log("Please checkout with checkout button");
        }
      },2500)
      
    }
  },[speech, audioRecords])

  return (
    <MQTTContext.Provider
      value={{
        setClient,
        client,
        mqttConnect,
        mqttDisconnect,
        connectStatus,
        qosOption,
        mqttUnSub,
        mqttSub,
        isSubed,
        mqttPublish,
        payload,
        handlePublish,
        postData,
        orderRes,
        CB_AEPLRes,
        orderIdRef,
        cartItemsRef,
      }}
    >
      {children}
    </MQTTContext.Provider>
  );
};
