// import React from 'react';
// import "./../Styles/Loader.css";

// const Loader = () => {
//     return (
//         <div id="overlay_for_loader">
//             <div id="loader_div"></div>
//         </div>
//     )
// }

// export default Loader;

import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import "./../Styles/Loader.css";

const Loading = () => {
    return (
        <div className="loading-container"> {/* Apply the class */}
            <FaSpinner className="spinner-icon" /> {/* Apply the class */}
        </div>
    );
};

export default Loading;
