import React, { useEffect } from "react";
import lottie from "lottie-web";
import animationData from "../lotties/PaymentSuccessfull.json";
import "../Styles/PaymentSuccess.css";
import { Link, useNavigate } from "react-router-dom";

const PaymentSuccessfull = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const container = document.querySelector("#payment-successfull");

    if (container) {
      // Load animation when the component mounts
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });

      // Clean up the animation when the component unmounts
      return () => {
        anim.destroy();
      };
    }
  }, []);

  setTimeout(() => {
    navigate('/paymentredirect')
  }, 5000);

  return (
    <>
      <div className="payment-success-container">
        <header className="payment-success-header">
          <Link to="/ordercomplete" className="link-tag-correction">
            <div className="payment-success-header-logoImg">
              <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
            </div>
          </Link>

          <div className="payment-success-text-main">
            <div className="payment-success-text">Payment Successful!</div>
          </div>
        </header>
        <div
          id="payment-successfull"
        />
      </div>
    </>
  );
};

export default PaymentSuccessfull;
