import React from "react";
import { Modal } from "react-bootstrap";
import "../Styles/ProductInfo.css";

const ProductInfo = (props) => {
  const handleCancel = () => {
    props.onHide();
  };

  const descriptions = {
    "Plain Cane": "Enjoy pure sugarcane juice... nothing more; nothing less!",
    "Lemon Cane": "A zesty twist of lemon in every sip of refreshing sugarcane juice!",
    "Lemon-Ginger Cane": "Tangy lemon meets spicy ginger in this invigorating sugarcane blend!",
    "Kokam Cane": "Experience the exotic, tangy flavor of kokam with our classic sugarcane juice!",
    "Mint Cane": "Cool and refreshing, this mint-infused sugarcane juice is perfect for a hot day!",
    "Awla-Tulsi Cane": "Boost your health with the Grandma’s recommended Awla and Tulsi in our sugarcane juice!"
  };

  const description = descriptions[props?.card?.title] || "No description available.";

  return (
    <>
      <Modal
        {...props}
        size="lg"
        dialogClassName="custom-product-info-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          handleCancel();
        }}
      >
        <Modal.Body>
          <button className="btn-close-btn" onClick={handleCancel}>
            <img
              src={require("../Assets/Close.png")}
              alt="Close"
              width={62}
              height={62}
            />
          </button>

          <div className="product-info-img">
          <div className="info-modal-title">{props?.card?.title}</div>
          <div className="info-modal-contain">{description}</div>
          <img src={`data:image/png;base64,${props?.card?.imageSrc}`}   alt="logo"
              height={"80%"}
              width={"80%"} />
            {/* <img
              src={require("../Assets/Flavor-1.png")}
              alt="logo"
              height={278}
              width={278}
            /> */}
          </div>
         
          <div className="info-modal-nutrition-value">100ml juice contains</div>
          <h2 className="calories_text">50 Calories</h2>
          <h2 className="calories_text">NO Saturated Fats and Cholesterol</h2>
          {/* <div className="nutrition-properties"> 50-60 Calories</div> */}
          <div className="info-modal-nutrition-value">Nutritional Value</div>

          <div className="nutrition-table">
            <div className="nutrition-row">
              <div className="nutrition-properties">Calories</div>
              <div className="nutrition-values">50 cal</div>
            </div>
            <div className="nutrition-row">
              <div className="nutrition-properties">Carbohydrate</div>
              <div className="nutrition-values">13 g</div>
            </div>
            <div className="nutrition-row">
              <div className="nutrition-properties">Protein</div>
              <div className="nutrition-values">0.3 g</div>
            </div>
            <div className="nutrition-row">
              <div className="nutrition-properties">Iron</div>
              <div className="nutrition-values">0.5 mg</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductInfo;
