import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CancelTransaction() {
  const navigate = useNavigate();

  useEffect(() => {
    const machine_id = localStorage.getItem('machine_id');
    if (machine_id) {
      navigate(`/?machine_id=${machine_id}`);
    } else {
      console.warn('No machine_id found in local storage');
      // Handle the case where machine_id is not found, if necessary
    }
  }, [navigate]);

  return (
    <div>CancelTransaction</div>
  );
}

export default CancelTransaction;
