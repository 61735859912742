import React, { useContext, useState } from "react";
import "../Styles/OrderProcessing.css";
import { Link, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import OrderQrCode from "../Components/OrderQrCode";
import PaymentSuccessGreenTick from "../images/transactionSuccessGreenTick.png";

import Loader from "../Components/Loader";
import { DataContext } from "../Context/DataContext";
import { MQTTContext } from "../Context/MQTTContext";

const OrderProcessing = () => {
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const {
    cartItems,
    updateCart,
    calculateOverallTotalPrice,
    calculateTotalPrice,
    TotalGlassQuantity,
    setOpenGlassError,
    openGlassError,
    db,
  } = useContext(DataContext);

  const { orderIdRef } = useContext(MQTTContext);

  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const calculateOverallPrice = localStorage.getItem("totalPrice");
  const transaction_id = localStorage.getItem("transaction_id");

  orderIdRef.current = null;
  localStorage.removeItem("orderId");
  const machine_id= localStorage.getItem("machine_id")

  return (
    <div className="order-processing-wrapper">
      {isLoading ? (
        <div style={{ width: "200px", margin: "auto", marginTop: "500px" }}>
          <Loader />
        </div>
      ) : (
        <>
          <div className="order-processing-main">
            <h4>Scan code order is ready now</h4>
            <div className="machine-failure-text-2">Order No. {orderId}</div>
            <br />
            <img
              src={PaymentSuccessGreenTick}
              className="payment-success-green-tick"
              alt="Transaction Success"
            ></img>
            <div className="success-container">
              <h2 className="transaction-success-message">
                Transaction Successful
              </h2>
              <div className="transaction-details">
                <p className="transaction-id">
                  Transaction ID:{" "}
                  <span className="transaction-id-value">{transaction_id}</span>
                </p>
                <p className="amount-message">
                  Amount: ₹ {calculateOverallPrice}.00
                </p>
              </div>
            </div>

            <div className="order-processing-card-container">
              <div className="order-processing-qr-code">
                <OrderQrCode />
              </div>
            </div>

            <div className="button-container">
              <Link to={`/?machine_id=${machine_id}`}>
                <button
                  className="button1"
                  onClick={() => {
                    localStorage.removeItem("orderId");
                  }}
                >
                  Back
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderProcessing;

// import React, { useState, useEffect, useContext } from "react";
// import "../Styles/OrderProcessing.css";
// import { useParams } from "react-router-dom";
// import animationData from "../lotties/PleaseCollect.json";
// import lottie from "lottie-web";
// import Footer from "../Components/Footer";
// import { MQTTContext } from "../Context/MQTTContext";
// import { DataContext } from "../Context/DataContext";

// // function to insert a item after specific index
// function atIndexIns(arr,ind,item){
//   if(ind === arr.length - 1){
//     arr[ind+1] = item;
//     return;
//   }
//   let temp;
//   temp = arr[ind+1];
//   arr[ind+1] = item;
//   if(ind === arr.length - 2){
//     arr[arr.length] = temp;
//     return;
//   }
//   let i  = ind+2;
//   arr.length++;
//   while (i < arr.length-1){
//     [arr[i], temp] = [temp, arr[i]];
//     i++;
//   }
//   arr[i] = temp;
// }

// // function to breakdown the quantities of order
// // function orderBreakDown(array){
// //   let cartItems = array.map((img,i,arr)=>{
// //     while(img.quantity > 1){
// //       // arr.push({...img,quantity:1});
// //       atIndexIns(arr,i,{...img,quantity:1})
// //       img.quantity -= 1;
// //     }
// //     return img;
// //   })
// //   return cartItems;

// // }
// function orderBreakDown(array){
//   for(let i = 0; i < array.length; i++){
//     while ( array[i].quantity > 1){
//       array.splice(i + 1, 0, { ...array[i], quantity: 1 });
//       array[i].quantity -= 1;
//     }
//   }
//   // console.log('order breakdown array',array);
//   // debugger;
// }

// // let lastR;
// // let lastR3;

// const OrderProcessing = () => {
//   const {orderId} = useParams();
//   const { orderRes, CB_AEPLRes, handlePublish, orderIdRef, cartItemsRef } = useContext(MQTTContext);
//   const { cartItems, setCartItems } = useContext(DataContext);
//   orderBreakDown(cartItemsRef.current);
//   if(orderId) {
//     orderIdRef.current = orderId;
//   }
//   console.log(orderId,"Order ID in order processing");
//   useEffect(() => {
//     const container = document.querySelector("#please-collect-animation");

//     if (container) {
//       // Load animation when the component mounts
//       const anim = lottie.loadAnimation({
//         container,
//         animationData,
//         renderer: "svg",
//         loop: true,
//         autoplay: true,
//       });

//       // Clean up the animation when the component unmounts
//       return () => {
//         anim.destroy();
//       };
//     }
//   }, []);

//   useEffect(()=>{
//     if(orderId) {
//       handlePublish();
//     }
//   },[orderId])

//   const [images, setImages] = useState(
//     [
//       ...cartItemsRef.current,
//     ].map((img,ind,arr) => {
//       return { ...img, showTick: false, showTitle: false, src: "image1.jpg"}
//     })
//   );
//   const [leftImages, setLeftImages] = useState(images);
//   const [centerImage, setCenterImage] = useState(null);
//   const [rightImages, setRightImages] = useState([]);
//   const [stopRightAnimation, setStopRightAnimation] = useState(false);
//   const [showTick, setShowTick] = useState(false);

//   useEffect(() => {
//     if(orderRes && orderRes?.order_status === 2 && CB_AEPLRes?.pending_quantity === leftImages.length - 0){
//       let condtion = true;
//       if(condtion ){
//         const imageToMove = leftImages[0];
//         setLeftImages(leftImages.slice(1));
//         setCenterImage(imageToMove);
//         setTimeout(()=>{
//           let centerImg = document.querySelector(".center-column img")
//           if(centerImg) centerImg.style.animationPlayState = "paused";
//         },2010);
//       }

//     }else if(orderRes && orderRes?.order_status === 3){
//       let condtion = true;
//       if(condtion){
//         if(centerImage) {
//           setCenterImage({...centerImage, showTitle: true})
//         }
//         setTimeout(()=>{
//           let centerImg = document.querySelector(".center-column img ")
//           if(centerImg) centerImg.style.animationPlayState = "running";
//         },1500)
//         if(centerImage) {
//           setTimeout(()=>{
//             setStopRightAnimation(true);
//           },1500)
//           setTimeout(()=>{
//             centerImage.showTick = true; // set showTick true when moving to right
//             centerImage.showTitle = false;
//             setRightImages([centerImage, ...rightImages]);
//             setCenterImage(null);
//             setStopRightAnimation(false);

//           },2500);
//         }
//       }

//     }
//     // lastR = {...orderRes};
//     // lastR3 = {...orderRes};
//   }, [leftImages, rightImages, orderRes, CB_AEPLRes, orderId, orderIdRef, cartItemsRef]);

//   useEffect(() => {
//     if(cartItems.length === 0) {
//       let crtItms = JSON.parse(localStorage.getItem('cartItems'));
//       if(crtItms) {
//         setCartItems(crtItms);
//         setTimeout(()=>{
//           console.log("Hanlde publish ... !")
//           // handlePublish();
//         },1000);
//       }
//     }
//   }, [cartItems])

//   return (
//     <>
//       <div className="order-processing-main">
//         <header className="order-processing-header">
//           <div className="order-processing-logo">
//             <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
//           </div>
//           <div className="machine-failure-text-main">
//             <div className="machine-failure-text-1">Preparing Your Drink</div>
//             <div className="machine-failure-text-2">
//               Order No. {orderId}
//             </div>
//           </div>
//         </header>
//         <div className="image-slider-app">
//           <div className="image-column left-column">
//             {leftImages.map((image, index) => (
//               <div key={index} className="image-container">
//                 <img
//                   src={require(`../images/${image.src}`)}
//                   alt={`Image ${index + 1}`}
//                   className="black-and-white"
//                   data-id={image.id}
//                 />
//                 <p>{image.title}</p>
//               </div>
//             ))}
//           </div>
//           <div className="image-column center-column">
//             {centerImage && (
//               <div className="image-container">
//                 <img
//                   className={`center-image myImagecentre`}
//                   src={require(`../images/${centerImage.src}`)}
//                   alt={centerImage.title}
//                   data-id={centerImage.id}
//                 />
//                 <p style={{ marginTop: "100px" }}>{centerImage.title}</p>
//                 {centerImage.showTitle && (
//                   <div className="imge-please-collect">
//                     <h3 className="image-title">Please Collect</h3>
//                     <img
//                       src={require("../images/arrow.png")}
//                       alt="Arrow Imag"
//                       className="arrow-image"
//                     />
//                     {/* <div
//                       id="please-collect-animation"
//                       className="arrow-image"
//                       style={{ width: 100, height: 200 }}
//                     /> */}
//                   </div>
//                 )}
//               </div>
//             )}
//           </div>
//           <div className="image-column right-column">
//             {rightImages.map((image, index) => (
//               <div key={index} className="image-container">
//                 <img
//                   src={require(`../images/${image.src}`)}
//                   alt={image.title}
//                   data-id={image.id}
//                   style={{
//                     animationPlayState: stopRightAnimation
//                       ? "paused"
//                       : "running",
//                   }}
//                 />
//                 {image.showTick && (
//                   <img
//                     src={require("../images/icon.png")}
//                     alt="Tick Mark"
//                     className="tick-mark-image"
//                   />
//                 )}
//                 <p>{image.title}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };
// export default OrderProcessing;
