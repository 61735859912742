import React from "react";
import "../Styles/OrderComplete.css";
import { Link } from "react-router-dom";

const OrderComplete = () => {
  return (
    <div className="order-complete-main">
      <header className="order-complete-header">
        <div className="order-complete-logo">
          <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
        </div>
        <div className="order-complete-header-text">
          <div className="order-complete-header-text-1">Cheers!</div>
          <div className="order-complete-header-text-2">
            You are now one step ahead of your fitness routine
          </div>
        </div>
      </header>
      <Link to="/feedback">
        <div className="order-complete-main-glass">
          <img src={require("../Assets/BigGlass.png")} alt="big-glass" />
        </div>
      </Link>
    </div>
  );
};

export default OrderComplete;
