// SomethingWentWrongPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import '../Styles/SomethingWentWrongPage.css'; // Import CSS file for styling

const SomethingWentWrongPage = () => {
  const navigate = useNavigate(); // Access to the history object

  // Function to handle redirection to homepage
  const redirectToHomepage = () => {
    navigate("/"); // Redirects to the homepage
  };

  // Clear local storage when the component mounts
  React.useEffect(() => {
    // localStorage.removeItem('orderId');
    // localStorage.removeItem('cartItems');
    // localStorage.removeItem('ordersArray');
    // localStorage.removeItem('newOrderObject');
  }, []);

  return (
    <div className="error-container">
      <h1>Oops! Something went wrong...</h1>
      <p>We apologize for the inconvenience. Please try again later.</p>
      <button class="button" onClick={redirectToHomepage}>Go to Homepage</button>
    </div>
  );
};

export default SomethingWentWrongPage;
