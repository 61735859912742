import React, { useContext, useEffect, useState } from "react";
import FlavorCard from "../Components/FlavorCard";
import Cart from "../Components/Cart";
import "../Styles/SelectFlavor.css";
import ProductInfo from "../Components/ProductInfo";
import { DataContext } from "../Context/DataContext";
import AudioRecorder from "../Components/AudioRecorder";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ScanMachineQRError from "./ScanMachineQRError";

const cardData = [
  {
    imageSrc: require("../Assets/Flavor-1.png"),
    title: "Plain Cane",
    rate: "₹ 100.00 ",
    Recipie: 1,
  },
  {
    imageSrc: require("../Assets/Flavor-2.png"),
    title: "Lemon Cane",
    rate: "₹ 100.00 ",
    Recipie: 2,
  },
  {
    imageSrc: require("../Assets/Flavor-3.png"),
    title: "Ginger Cane",
    rate: "₹ 100.00 ",
    Recipie: 3,
  },
  {
    imageSrc: require("../Assets/Flavor-4.png"),
    title: "Lemon Ginger Cane",
    rate: "₹ 100.00 ",
    Recipie: 4,
  },
  {
    imageSrc: require("../Assets/Flavor-5.png"),
    title: "Kokum Cane",
    rate: "₹ 100.00 ",
    Recipie: 5,
  },
  {
    imageSrc: require("../Assets/Flavor-6.png"),
    title: "Jal Jeera Cane",
    rate: "₹ 100.00 ",
    Recipie: 6,
  },
];

const SelectFlavor = () => {
  const { products, loading, setMachineId, machine_Id, machineData, machineWiseData } = useContext(DataContext);
  const [openProductInfo, setOpenProductInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [machineIdError, setMachineIdError] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const machine_id = searchParams.get('machine_id');

  localStorage.setItem("machine_id", machine_id);



  setTimeout(() => {
    setIsLoading(false);
  }, 2000);


  useEffect(() => {

    // Check if SugarCane or Paper Cups quantity is less than 1
    const sugarCane = machineWiseData.find(item => item.itemName === "SugarCane");
    const paperCups = machineWiseData.find(item => item.itemName === "Paper Cups");

    if ((sugarCane && sugarCane.quantity <= 1.25) || (paperCups && paperCups.quantity === 0)) {
      navigate('/outofstock');
    }
  }, [machineWiseData]);

  useEffect(() => {
    console.log('machineId:', machine_id);
    if (machine_id) {
      setMachineId(machine_id);
    } else {
      setMachineIdError(true);
      setIsLoading(false);
    }
  }, [machine_id, setMachineId, machine_Id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {machineIdError || machineData?.length < 1 ? (
            // <>
            //   <img class="error-message-image" src="https://i.pinimg.com/originals/e4/af/9f/e4af9f0025a8ce68bee2cf5a1360a501.gif" alt="Error GIF"></img>

            // <div class="error-message">
            // Invalid URL. Please scan the QR from the machine to get the correct URL
            // </div>
            // </>
            <>
              <header className="selectflavor-header">
                <div className="selectflavor-header-logo">
                  <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
                </div>
              </header>
              <ScanMachineQRError />
            </>


          ) : (
            <>
              <div className="selectflovr-main">
                <header className="selectflavor-header">
                  <div className="selectflavor-header-logo">
                    <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
                  </div>

                  <div className="selectflavor-header-text-main">
                    <div className="selectflavor-header-title-1">Make Your Choice</div>
                  </div>
                </header>

                <FlavorCard cardData={products} setOpenProductInfo={setOpenProductInfo} />
                <Cart />
              </div>

              <ProductInfo
                show={openProductInfo}
                onHide={() => setOpenProductInfo(false)}
              />
              {/* <Footer/> */}
            </>
          )}
        </>
      )}

      {/* {loading && <Loader />} */}
    </>
  );
};

export default SelectFlavor;
