import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [openGlassError, setOpenGlassError] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [speech, setSpeech] = React.useState("");
  const [audioRecords, setAudioRecords] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [call, setCall] = React.useState(0);
  const [db, setDb] = React.useState(null);
  const [discountPercentage, setDiscountPercentage]= useState(0)
  const [discountedAmount, setDiscountedAmount] = useState(0)
  const [machine_Id, setMachineId] = useState();
  const [machineData , setMachineData] = useState();
  const [machineWiseData, setMachineWiseData] = useState([]);


  // Function to calculate the total price for an item
  const calculateTotalPrice = (item) => {
    // const rate = parseFloat(item.rate.replace("₹", "").trim());
    const rate = item.rate;
    return item.quantity * rate;
  };

  // Function to calculate the overall total price of the cart
  const TotalPrice = () => {
    let overallTotal = 0;
    cartItems.forEach((item) => {
      overallTotal += calculateTotalPrice(item);
    });
    return overallTotal;
  };

  useEffect(() => {
    console.log("machine_Id", machine_Id)
    if(machine_Id){
      fetchMachineData();
      getMachineWiseStock(machine_Id)

    }

    console.log("machineWiseData", machineWiseData)
    
  }, [machine_Id]);

  // const WEB_ORDER_API_URL= process.env.WEB_ORDER_API_URL
  const REACT_APP_WEB_ORDER_API_URL= process.env.REACT_APP_WEB_ORDER_API_URL
  // const WEB_ORDER_API_URL= "http://localhost:9001/api"


  console.log("process.env.REACT_APP_WEB_ORDER",process.env )

  const getMachineWiseStock = async (machineId) => {
    try {
        // setLoading(true);
        const response = await axios.get(`${REACT_APP_WEB_ORDER_API_URL}/getMachineWiseStock`, {
            params: { machineId }
        });
        setMachineWiseData(response.data);
    } catch (err) {
        console.error(err);
    }
};

  const fetchMachineData = async () => {
    console.log("machine_Id", machine_Id)
    try {
      const response = await axios.get(`${REACT_APP_WEB_ORDER_API_URL}/machine_Id/${machine_Id}`);
      setMachineData(response.data);
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };
 
  useEffect(()=>{
    
    if(machine_Id){
      // console.log("machineData", machineData[0]?.Discount || 0)
      console.log("machineData", machineData[0]?.Discount)
      setDiscountPercentage(machineData[0]?.Discount)
    }
     
  },[discountPercentage, machineData])

  const calculateOverallTotalPrice = () => {
    let overallTotal = 0;
    cartItems.forEach((item) => {
      overallTotal += calculateTotalPrice(item);
    });

    // Calculate discount amount
    let discountAmount = 0;
    if (discountPercentage > 0 && discountPercentage <= 100) {
      discountAmount = (overallTotal * discountPercentage) / 100;
      overallTotal -= discountAmount;
    }

    // Set discounted amount in state
    setDiscountedAmount(discountAmount);

    return overallTotal;
  };

  // ... Your existing code ...

  const TotalGlassQuantity = cartItems.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.quantity;
  }, 0);

  const updateCart = (product, newQuantity) => {
    if (newQuantity >= 0) {
      const updatedCart = cartItems.map((item) =>
        item.title === product.title ? { ...item, quantity: newQuantity } : item
      );
      setCartItems(updatedCart);
    }
  };

  const incrementQuantity = (product) => {
    const existingItem = cartItems.find((item) => item.title === product.title);
    if (existingItem) {
      if (TotalGlassQuantity < 6) {
        updateCart(product, existingItem.quantity + 1);
      } else {
        setOpenGlassError(true); // Set error modal to true
      }
    } else {
      if (TotalGlassQuantity < 6) {
        setCartItems([...cartItems, { ...product, quantity: 1 }]);
      } else {
        setOpenGlassError(true); // Set error modal to true
      }
    }
  };

  const decrementQuantity = (product) => {
    const existingItem = cartItems.find((item) => item.title === product.title);
    if (existingItem && existingItem.quantity > 1) {
      updateCart(product, existingItem.quantity - 1);
    } else {
      const updatedCart = cartItems.filter(
        (item) => item.title !== product.title
      );
      setCartItems(updatedCart);
    }
  };

  // React.useEffect(()=>{
  //   let db;
  //   let request = window.indexedDB.open('canebot',2);
  //   request.onerror = (handler)=>{
  //       console.log(handler,'error');
  //   }
  //   request.onsuccess = (handler)=>{
  //       console.log(handler,'success');
  //       db = handler.target.result;
  //       setDb(db);
  //       db.onerror = (event)=>{
  //           console.log(event,'database error')
  //       }
  //       afterOpenDB();
  //   }
  //   request.onupgradeneeded = (handler)=>{
  //       console.log(handler,'upgrade needed');
  //       const objectStore = db.createObjectStore("orders", { keyPath: "orderNo" });
  //   }
  //   function afterOpenDB(){
  //       console.log(db,'db');
  //   }
  // },[])

  // React.useEffect(()=>{
  //   if(db) {
  //     console.log("This is db",db);
  //   }
  // },[db])

  // Use Effect to fetch the available flavours
  // React.useEffect(()=>{
  //   async function getProducts(){
  //     const Url = "http://localhost:3000";
  //     // const Url = "https://orders.canebot.com"
  //     let url = Url.replace(/orderprocessing\/\d+/,"");
  //     axios.get(url+"/products.json").then((res)=>setProducts(res.data))
  //   }
  //   getProducts();
  // },[])

  React.useEffect(()=>{
    const PRODUCT_API = `${process.env.REACT_APP_URL}`
    async function getProducts(){
      const Url = `${PRODUCT_API}/get/flavour`;
      // let url = Url.replace(/orderprocessing\/\d+/,"");
      console.log("URL-->",Url)
      axios.get(Url).then((res)=>setProducts(res.data))
    }
    getProducts();
  },[])

  return (
    <DataContext.Provider
      value={{
        decrementQuantity,
        incrementQuantity,
        cartItems,
        setCartItems,
        updateCart,
        calculateOverallTotalPrice,
        calculateTotalPrice,
        setOpenGlassError,
        openGlassError,
        TotalGlassQuantity,
        products,
        setProducts,
        speech,
        setSpeech,
        loading,
        setLoading,
        audioRecords, 
        setAudioRecords,
        call,
        setCall,
        db,
        TotalPrice,
        discountedAmount,
        machine_Id,
        setMachineId,
        setCartItems,
        discountPercentage,
        machineData,
        machineWiseData
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
