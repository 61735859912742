import React, { useContext, useEffect } from "react";
import lottie from "lottie-web";
import animationData from "../lotties/MachineFailure.json";
import "../Styles/MachineFailure.css";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../Context/DataContext";

const MachineFailure = () => {
  useEffect(() => {
    const container = document.querySelector("#machine-failure");

    if (container) {
      // Load animation when the component mounts
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });

      // Clean up the animation when the component unmounts
      return () => {
        anim.destroy();
      };
    }
  }, []);
  const { setCartItems } = useContext(DataContext);
  const navigate = useNavigate();
  const handleNavigate = () => {
    setCartItems([]);
    navigate("/");
  };
  const orderId = useParams();

  return (
    <>
      <div className="machine-failure-container">
        <header className="machine-failure-header">
          <div className="machine-failure-header-logoImg">
            <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
          </div>
          <div className="machine-failure-text-main">
            <div className="machine-failure-text-1">
              Oops! Something Went Wrong
            </div>
            <div className="machine-failure-text-2">
              Order No. {orderId.orderId}
            </div>
          </div>
        </header>
        <div id="machine-failure" />

        <div className="machine-failure-support-main">
          <div>
            <img
              src={require("../Assets/feature-image@2x.png")}
              alt="canelogo"
              width={260}
              height={240}
            />
          </div>
          <div className="machine-failure-support">
            <div className="machine-failure-help">For Help</div>
            <div>Scan This QR From Your Phone</div>
            <div>OR</div>
            <div>Contact +91739928390</div>
          </div>
        </div>

        <div className="buttons">
          <Button className="fail-cancel-button" onClick={handleNavigate}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default MachineFailure;
