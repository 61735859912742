import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import animationData from "../lotties/MachineFailure.json"; // Ensure you have an appropriate Lottie animation
import "../Styles/OutOfStock.css"; // Ensure this CSS file exists
// import "../Styles/MachineFailure.css";
// import "../Styles/MachineMaintenance.css";
import Footer from "../Components/Footer";
import { IoBan } from "react-icons/io5";
import { Button } from "react-bootstrap";

const OutOfStock = () => {
  useEffect(() => {
    const container = document.querySelector("#out-of-stock-animation");

    if (container) {
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });

      return () => {
        anim.destroy();
      };
    }
  }, []);
  const machine_id = localStorage.getItem("machine_id");

  return (
    <>
      <div className="out-of-stock-container">
        <header className="out-of-stock-header">
          <div className="out-of-stock-header-logoImg">
            <img src={require("../Assets/CanebotLogo.png")} alt="canelogo" />
          </div>
        </header>
        <div id="out-of-stock-animation" />
        <div className="out-of-stock-text-main">
          <div className="out-of-stock-text-1">
            <IoBan style={{ marginRight: "20px", color: "brown" }} />
            Out of Stock!
          </div>
          <div className="out-of-stock-text-2">
            We're sorry, but some of the items are currently out of stock. Please check back later or contact support for assistance.
          </div>
        </div>
        <div className="out-of-stock-support-main">
          <div>
            <img
           src={require("../Assets/feature-image@2x.png")}
              alt="outofstock"
              width={260}
              height={240}
            />
          </div>
          <div className="out-of-stock-support">
            <div className="out-of-stock-help">For Help</div>
            <div>Scan This QR From Your Phone</div>
            <div>OR</div>
            <div>Contact +919881234589</div>
            <Link to={`/?machine_id=${machine_id}`}>
                <Button className="back-to-home-button">Back to Home</Button>
            </Link>
          </div>
        </div>
      </div>
      {/* <Footer style={{ marginTop: "40px" }} /> */}
    </>
  );
};

export default OutOfStock;
