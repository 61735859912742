import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "../Styles/FlavorCard.css";
import { DataContext } from "../Context/DataContext";
import ProductInfo from "./ProductInfo";
import infoIcon from "../Assets/info.png"; // Import the info icon at the top

const FlavorCard = ({ cardData }) => {
  const { incrementQuantity, cartItems, discountPercentage, machineWiseData } = useContext(DataContext);
  const [openProductInfo, setOpenProductInfo] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleProductInfoOpen = (card) => {
    setSelectedCard(card);
    setOpenProductInfo(true);
  };

  const getItemQuantity = (itemName) => {
    const item = machineWiseData.find((machineItem) => machineItem.itemName == itemName);
    return item ? item.quantity : 0;
  };

  const sortedCardData = cardData.slice().sort((a, b) => a.sequence - b.sequence);

  return (
    <div className="flavor-card-main">
      <Row>
        {sortedCardData.map((card, index) => {
          const quantity = getItemQuantity(card.recipie);
          const isSoldOut = quantity < 1;
          console.log("isSoldOut", isSoldOut)

          return (
            <Col key={index} sm={6} xs={6} md={6} lg={6} xl={6} xxl={6} className="custom-col">
              <div className="card-flavor-card">
                <div className="card-subdiv">
                  <div className="img-div" onClick={() => handleProductInfoOpen(card)}>
                    <img
                      src={`data:image/png;base64,${card.imageSrc}`}
                      className={card.available ? "card-img" : "card-img-unavailable"}
                      alt="logo"
                      width={30}
                    />
                    <img
                      src={infoIcon}
                      alt="info icon"
                      className="card-img-info"
                      width={30}
                      onClick={() => setOpenProductInfo(true)}
                    />
                  </div>
                  <div className="text-div">
                    <div className="subtitle" style={{ color: card && card.available ? "black" : "grey" }}>
                      {card.title}
                    </div>
                    {discountPercentage > 0 && (
                      <div className="rate" style={{ color: "grey", textDecoration: "line-through", textDecorationColor: "red", textDecorationThickness: "2px", fontSize: "0.8em" }}>
                        ₹ {card.rate}.00
                      </div>
                    )}
                    <div className="rate" style={{ color: card && card.available ? "black" : "grey" }}>
                      ₹ {discountPercentage > 0 ? (card.rate * (100 - discountPercentage) / 100).toFixed(0) : card.rate}.00
                    </div>
                    <div className="button-div">
                      <Button
                        variant={isSoldOut ? "secondary" : "info"}
                        style={isSoldOut ? { fontSize: '10px', padding: '5px 10px' } : {}}
                        className={
                          card && card.available ? 
                          cartItems.find((item) => item.title === card.title)
                            ?.quantity > 0
                            ? "added-button"
                            : "add-button" : "added-button"
                        }
                        onClick={() => incrementQuantity(card)}
                        disabled={
                          isSoldOut || 
                          (card && card.available ? 
                          cartItems.find((item) => item.title === card.title)
                            ?.quantity > 0
                            ? true
                            : false : true)
                        }
                        
                      >
                        {/* {isSoldOut ? "Sold Out" : (!card.available ? "NA" : (cartItems.find((item) => item.title === card.title)?.quantity > 0 ? "Added" : "Add"))} */}
                        {
                          !card.available ? "NA" :
                          isSoldOut ? "Sold Out" :
                          (cartItems.find((item) => item.title === card.title)?.quantity > 0 ? "Added" : "Add")
                        }

                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      {selectedCard && 
        <ProductInfo
          show={openProductInfo}
          onHide={() => setOpenProductInfo(false)}
          card={selectedCard}
        />
      }
    </div>
  );
};

export default FlavorCard;
