import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BarCode from "../Components/BarCode";
import "../Styles/MakePayment.css";
import animationData from "../lotties/PaymentTimeOut.json";
import lottie from "lottie-web";
import Loader from "../Components/Loader";

const MakePayment = () => {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  useEffect(() => {
    const container = document.querySelector("#time-left-animation");

    if (container) {
      // Load animation when the component mounts
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });

      // Clean up the animation when the component unmounts
      return () => {
        anim.destroy();
      };
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="payment-container">
          <div className="back-button">
            <Link to="/selectflavor">
              <Button className="back-button-style">Back</Button>
            </Link>
          </div>

          <div className="title">Scan and Pay</div>
          <BarCode />
          <Link to="/machinefailure">
            <div className="payment-subtitle">Or Pay Using</div>
          </Link>

          <Link to="/paymentsuccess">
            <div className="card-image">
              <img src={require("../Assets/mastercard.png")} alt="card" />
            </div>
          </Link>

          <div className="card-text">Card</div>
          <div className="time-left">
            <div className="time-left-animation-text">
              <div>02:00</div>
              <div>Mins Left</div>
            </div>

            <div id="time-left-animation" />
          </div>
        </div>
      )}
    </>
  );
};

export default MakePayment;
