import React, { useContext, useEffect } from 'react';
import { MQTTContext } from "../Context/MQTTContext";
import { useLocation } from 'react-router-dom';

let flag = false;
const PaymentRedirect = (props) => {
    const { handlePublish } = useContext(MQTTContext);
    const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tracking_id = searchParams.get('tracking_id');
        // Now you have access to the transactionId, you can use it as needed
        console.log('Transaction ID:', tracking_id);
        localStorage.setItem('transaction_id', tracking_id);
    }, [location.search]);

    useEffect(()=>{
        if(!flag) {
            setTimeout(()=>{
                handlePublish();
                flag = true;
            })
        }
    },[])
    return (
        <div style={{visibility:"hidden"}} >

        </div>
    )
}

export default PaymentRedirect;